import * as React from "react";
import logo from "../images/ServaroLogoAlphaMinTight.png";
import "../global.css";

const IndexPage = () => {
  return (
    <main className="h-screen p-10 md:p-20 lg:p-32 text-[#F7F7F7] bg-[#74688D] flex flex-col justify-center">
      <img className="w-44 lg:w-60" src={logo} alt="Servaro" />
      <h1 className="font-semibold text-xl md:text-2xl lg:text-4xl pb-4">
        Customer Service software for how teams actually work
      </h1>
      <p className="font-light text-lg md:text-xl lg:text-2xl lg:max-w-5xl pb-4 md:pb-8">
        A single foundation for customer data and business logic. Unify your post-sales operating workflows at any
        scale, without having to cobble together multiple systems.
      </p>
      <p className="italic md:text-lg">founders[at]servaro.com</p>
    </main>
  );
};

export default IndexPage;

export const Head = () => <title>Servaro</title>;
